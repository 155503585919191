import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location, LocationData } from 'src/app/models/location';
import { environment } from 'src/environments/environment';
import { NgHttpCachingService } from 'ng-http-caching';
import { map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private header = {
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  };
  private http = inject(HttpClient);
  private ngHttpCachingService = inject(NgHttpCachingService);

  constructor() {}

  // LOCATION

  public getLocations() {
    return this.http
      .get<Location[]>(environment.matchMakingApiUrl + '/v1/locations', {
        headers: this.header,
      })
      .pipe(
        map((locations) => locations.filter((location) => !location.deleted))
      );
  }

  public getHeadquarterForCompany(companyId: string) {
    return this.getLocationByCompany(companyId).pipe(
      map((locations) => locations.find((location) => location.headquarter))
    );
  }

  public getHeadquarter() {
    return this.getLocations().pipe(
      map((locations) => locations.find((location) => location.headquarter))
    );
  }

  public patchHeadquarter(companyId: string, location: Partial<Location>) {
    this.ngHttpCachingService.clearCacheByRegex(/locations/);
    return this.getHeadquarterForCompany(companyId).pipe(
      switchMap((hq) => this.putLocation({ ...hq, ...location }))
    );
  }

  public addLocation(location: LocationData) {
    this.ngHttpCachingService.clearCacheByRegex(/locations/);
    return this.http.post<Location>(
      environment.matchMakingApiUrl + '/v1/locations',
      location,
      {
        headers: this.header,
      }
    );
  }

  public putLocation(location: LocationData) {
    this.ngHttpCachingService.clearCacheByRegex(/locations/);
    return this.http.put<Location>(
      environment.matchMakingApiUrl + '/v1/locations',
      location,
      {
        headers: this.header,
      }
    );
  }

  public deleteLocation(id: string) {
    this.ngHttpCachingService.clearCacheByRegex(/locations/);
    return this.http.delete<Location>(
      environment.matchMakingApiUrl + `/v1/locations/${id}`,
      {
        headers: this.header,
      }
    );
  }

  public getLocationById(id: string) {
    return this.http.get<Location>(
      environment.matchMakingApiUrl + `/v1/locations/${id}`,
      {
        headers: this.header,
      }
    );
  }

  public getLocationByCompany(companyId: string) {
    return this.http
      .get<Location[]>(
        environment.matchMakingApiUrl + `/v1/locations/?companyId=${companyId}`,
        {
          headers: this.header,
        }
      )
      .pipe(
        map((locations) => locations.filter((location) => !location.deleted))
      );
  }
}
